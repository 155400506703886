<template>
  <div class="p-grid">
    <div class="p-col-12 p-md-6">
      <div class="p-fluid p-grid">
        <h1>{{ $t("help.title") }}</h1>
        <div class="p-field p-col-12">
          <p>{{ $t("help.description") }}</p>
          <ul>
            <li>{{ $t("help.step_one_description") }}</li>
            <li>{{ $t("help.step_two_description") }}</li>
          </ul>
          <p>
            {{ $t("help.sample") }}
            <a
              href="https://bioobserver.hertasecurity.com/download/samples.zip"
              target="_blank"
              >{{ $t("help.here") }}.</a
            >
          </p>
          <h2>{{ $t("help.step_one.title") }}</h2>
          <h4>{{ $t("help.step_one.upload_video") }}</h4>
          <p>{{ $t("help.step_one.text1") }}</p>
          <figure class="screenshot-figure p-m-0">
            <img src="/assets/help/1-new-video.png" alt="Add new video" />
          </figure>
          <p>
            <i>{{ $t("help.step_one.video_formats") }}</i>
          </p>
          <p>{{ $t("help.step_one.text2") }}</p>
          <h4>{{ $t("help.step_one.process") }}</h4>
          <p>{{ $t("help.step_one.text3") }}</p>
          <p>{{ $t("help.step_one.text4") }}</p>
          <h2>{{ $t("help.step_two.title") }}</h2>
          <p>{{ $t("help.step_two.text1") }}</p>
          <figure class="screenshot-figure p-m-0">
            <img src="/assets/help/3-process-status.png" alt="Process status" />
          </figure>
          <p>{{ $t("help.step_two.text2") }}</p>
          <p>{{ $t("help.step_two.guide") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Help",
};
</script>

<style scoped>
.screenshot-figure img {
  max-width: 100%;
}
</style>
